<template>
    <div class="section_header">
        <button type="button" class="add_btn pointer" @click="contactAddWizard = true" v-tooltip="`Add contacts to your account`" position="bottom-right">
            <img src="@/assets/images/add_client.svg">
            Add Contacts
        </button>
        <div class="tutorial_btn" @click="tutorialList = !tutorialList;" v-click-outside="closeTutorialListDropdown">
            <label>Tutorials</label>
            <img src="@/assets/images/play.svg">
            <div class="dropdown_wpr" :class="tutorialList ? 'active' : ''">
                <ul>
                    <li @click="tutorial = true;">How to use the contacts feature</li>
                </ul>
            </div>
        </div>
        <ul class="ml-auto">
            <!-- <li v-if="showContactRefreshButton" @click="clearFilter()">
                <button type="button" class="add_btn pointer"><i class="fas fa-sync"></i>Refresh</button>
            </li> -->
            <li class="optionDrops search_area">
                <input type="text" placeholder="Search" v-model="contactSearch" ref="search" @keyup.enter="forceContactSearch()" />
                <button class="search_btn" @click="forceContactSearch()">
                    <i class="fas fa-search"></i>
                </button>
            </li>
            <li v-if="showContactRefreshButton" class="export" @click="clearFilter()">
                <h5 v-tooltip="`Refresh contacts`" position="bottom-left">
                    <img src="@/assets/images/refresh.svg">
                </h5>
            </li>
            <li class="export" :class="{ 'active_filter' : hasFilter }" @click="hasFilter ? clearFilter() : filterFunc()">
                <h5 v-tooltip="hasFilter ? `Clear filters` : `Filter your contacts by certain attributes`" position="bottom-left">
                    <img v-if="hasFilter" src="@/assets/images/clear-filter.svg">
                    <img v-else src="@/assets/images/filter.svg">
                </h5>
            </li>
            <li class="export" @click="editStatusTabs = true">
                <h5 v-tooltip="`Create and manage contact segments`" position="bottom-left">
                    <img src="@/assets/images/segment.svg">
                </h5>
            </li>
            <li class="export" @click="exportInfo = !exportInfo">
                <h5 class="border-0" v-tooltip="`Export your contacts by filters`" position="bottom-left">
                    <img src="@/assets/images/export2.svg">
                </h5>
            </li>
            <li class="export" @click="toggleTablePreferences = !toggleTablePreferences">
                <h5 v-tooltip="`Configure your contact center preferences`" position="bottom-left">
                    <img src="@/assets/images/gear.svg">
                </h5>
            </li>
        </ul>
    </div>
    <status-tabs-modal v-model="editStatusTabs" />
    <contact-add-wizard v-model="contactAddWizard" />
    <div class="modal secondary video_player" v-if="tutorial">
        <div class="modal_container">
            <div class="modal_header">
                <button class="close_btn" @click="tutorial = false"><i class="fas fa-times"></i></button>
            </div>
            <div class="video_wpr">
                <iframe src="https://www.loom.com/embed/897c20940bb7451ebfa79adaf763bf69?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
            </div>
            <div class="modal_footer">
                <a href="https://help.thrivecoach.io/" target="_blank" class="more_tutorial">More Tutorials <i class="fas fa-long-arrow-alt-right"></i></a>
            </div>
        </div>
    </div>

    <!--------------- Add Filter ---------------->
    <div class="modal" v-show="modelValue" :class="{ 'active' : modelValue }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Add Filter</h1>
                    <small class="pointer" @click="clearFilter()" v-if="hasFilter">
                        Clear Filter
                    </small>
                </div>
                <button class="close_btn left_out" @click="closeFilterInfo"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <search-filter ref="search-filter" />
                </div>
                <div class="switch_wpr ">
                    <div class="setting_wpr">
                        <label for="broadcast_email" class="switch_option capsule_btn border-0 mb-1">
                            <h5>By Unopened Broadcast Emails <span>Includes Unopened Broadcast Emails</span></h5>
                            <input type="checkbox" :false-value="0" true-value="broadcast" id="broadcast_email" name="unopened_email" v-model="unopenedParams.type" hidden>
                            <div><span></span></div>
                        </label>
                        <div class="mb-4 unopens-filter" v-if="unopenedParams.type == 'broadcast'">
                            <h5><span>Contact has not opened a broadcast email in <input type="number" v-model="unopenedParams.days" /> days</span></h5>
                        </div>
                        <label for="sequence_email" class="switch_option capsule_btn border-0 mb-1">
                            <h5>By Unopened Sequence Emails<span>Includes Unopened Sequence Emails</span></h5>
                            <input type="checkbox" :false-value="0" true-value="sequence" id="sequence_email" name="unopened_email" v-model="unopenedParams.type" hidden>
                            <div><span></span></div>
                        </label>
                        <div class="mb-4 unopens-filter" v-if="unopenedParams.type == 'sequence'">
                            <h5><span>Contact has not opened sequence email in <input type="number" v-model="unopenedParams.days" /> days</span></h5>
                        </div>
                        <label for="any_email" class="switch_option capsule_btn border-0 mb-1">
                            <h5>By All Unopened Emails <span>Includes Any Unopened Emails</span></h5>
                            <input type="checkbox" :false-value="0" true-value="any" id="any_email" name="unopened_email" v-model="unopenedParams.type" hidden>
                            <div><span></span></div>
                        </label>
                        <div class="mb-4 unopens-filter" v-if="unopenedParams.type == 'any'">
                            <h5><span>Contact has not opened any email in <input type="number" v-model="unopenedParams.days" /> days</span></h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button class="btn cancel_btn" @click="closeFilterInfo">Cancel</button>
                <button class="btn save_btn" @click="handleFilterApply()">Submit</button>
            </div>
        </div>
    </div>

    <!--------------- Export Contacts ---------------->
    <div class="modal" v-if="exportInfo" :class="{ 'active' : exportInfo }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Export Contacts</h1>
                </div>
                <button class="close_btn left_out" @click="exportInfo = false;"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="switch_wpr">
                    <div class="setting_wpr">
                        <label for="all-Dates" class="switch_option capsule_btn border-0 mb-1">
                            <h5>All Time</h5>
                            <input type="checkbox" id="all-Dates" v-model="allDates" @change="toggleAllDates" hidden>
                            <div><span></span></div>
                        </label>
                        <div class="form_grp" v-if="!allDates">
                            <div class="group_item">
                                <label class="input_label">From date</label>
                                <div class="field_wpr">
                                    <datepicker v-model="exportParams.from" :max-date="maxStartDate" auto-apply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                </div>
                            </div>
                            <div class="group_item">
                                <label class="input_label">To date</label>
                                <div class="field_wpr">
                                    <datepicker v-model="exportParams.to" :min-date="minEndDate" auto-apply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                </div>
                            </div>
                        </div>
                        <label for="all-parameters" class="switch_option capsule_btn border-0 mb-1">
                            <h5>All Data <span>Includes Name, Joined, E-mail, Phone etc..</span></h5>
                            <input type="checkbox" :true-value="1" :false-value="0" id="all-parameters" v-model="exportParams.all" hidden>
                            <div><span></span></div>
                        </label>
                        <div v-if="!exportParams.all" class="switch_wpr">
                            <label for="export-name" class="switch_option capsule_btn border-0 m-0">
                                <h5>Name</h5>
                                <input type="checkbox" name="name" v-model="exportParams.fields" value="name" id="export-name" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="export-join-date" class="switch_option capsule_btn border-0 m-0">
                                <h5>Joined</h5>
                                <input type="checkbox" name="date_created" v-model="exportParams.fields" value="date_created" id="export-join-date" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="export-email" class="switch_option capsule_btn border-0 m-0">
                                <h5>E-mail</h5>
                                <input type="checkbox" name="email" v-model="exportParams.fields" value="email" id="export-email" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="export-phone" class="switch_option capsule_btn border-0 m-0">
                                <h5>Phone</h5>
                                <input type="checkbox" name="phone" v-model="exportParams.fields" value="number" id="export-phone" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="export-temperature" class="switch_option capsule_btn border-0 m-0">
                                <h5>Temperature</h5>
                                <input type="checkbox" name="temperature" v-model="exportParams.fields" value="temperature" id="export-temperature" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="export-status" class="switch_option capsule_btn border-0 m-0">
                                <h5>Status</h5>
                                <input type="checkbox" name="export_status" v-model="exportParams.fields" value="status" id="export-status" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="export-last-action" class="switch_option capsule_btn border-0 m-0">
                                <h5>Last Action</h5>
                                <input type="checkbox" name="last_action" v-model="exportParams.fields" value="last_action" id="export-last-action" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="export-birthdate" class="switch_option capsule_btn border-0 m-0 py-1">
                                <h5>DOB</h5>
                                <input type="checkbox" name="last_action" v-model="exportParams.fields" value="birthdate" id="export-birthdate" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="export-address" class="switch_option capsule_btn border-0 m-0 py-1">
                                <h5>Address</h5>
                                <input type="checkbox" name="last_action" v-model="exportParams.fields" value="address" id="export-address" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="export-city" class="switch_option capsule_btn border-0 m-0 py-1">
                                <h5>City</h5>
                                <input type="checkbox" name="last_action" v-model="exportParams.fields" value="city" id="export-city" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="export-state" class="switch_option capsule_btn border-0 m-0 py-1">
                                <h5>State</h5>
                                <input type="checkbox" name="last_action" v-model="exportParams.fields" value="state" id="export-state" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="export-country" class="switch_option capsule_btn border-0 m-0 py-1">
                                <h5>Country</h5>
                                <input type="checkbox" name="last_action" v-model="exportParams.fields" value="country" id="export-country" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="export-zipcode" class="switch_option capsule_btn border-0 m-0 py-1">
                                <h5>Zipcode</h5>
                                <input type="checkbox" name="last_action" v-model="exportParams.fields" value="zipcode" id="export-zipcode" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="export-tags" class="switch_option capsule_btn border-0 m-0">
                                <h5>Tags</h5>
                                <input type="checkbox" name="tags" v-model="exportParams.fields" value="tags" id="export-tags" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <label for="suppression-exclude" class="switch_option capsule_btn border-0 mb-1">
                            <h5>Exclude Suppressed Addresses</h5>
                            <input type="checkbox" id="suppression-exclude"  :true-value="1" :false-value="0" v-model="exportParams.suppression"  hidden>
                            <div><span></span></div>
                        </label>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Select segments</label>
                                <div class="field_wpr">
                                    <multiselect
                                        v-model="exportParams.status"
                                        :groups="true"
                                        :searchable="true"
                                        :options="statusesGroup"
                                        valueProp="value"
                                        label="title"
                                        placeholder="Select segment"
                                        :group-select="false"
                                        group-label="type"
                                        group-options="items"
                                        :group-hide-empty="true"
                                        :loading="statusTabsLoader"
                                    >
                                        <template v-slot:grouplabel="{ group }">
                                            <span class="status-tabs-header">{{ group.type }}</span>
                                        </template>
                                        <template v-slot:option="{ option }">
                                            <span>{{ option.title }}</span>
                                    </template>
                                    </multiselect>
                                </div>
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Select Tags</label>
                                <div class="field_wpr">
                                    <multiselect
                                        v-model="exportParams.tags"
                                        :groups="true"
                                        :searchable="true"
                                        :options="tagsGroup"
                                        value-prop="id"
                                        label="name"
                                        placeholder="Select tag"
                                        :group-select="false"
                                        group-label="type"
                                        group-options="tags"
                                        :group-hide-empty="true"
                                        mode="tags"
                                    >
                                        <template v-slot:grouplabel="{ group }">
                                            <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                        </template>
                                        <template v-slot:option="{ option }">
                                            <span>{{ option.name }}</span>
                                        </template>
                                        <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                            <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                            {{ option.name }}
                                            <span
                                                v-if="!disabled"
                                                class="multiselect-tag-remove"
                                                @mousedown.prevent="handleTagRemove(option, $event)"
                                            >
                                                <span class="multiselect-tag-remove-icon"></span>
                                            </span>
                                            </div>
                                        </template>
                                    </multiselect>
                                </div>
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Select group</label>
                                <div class="field_wpr">
                                    <multiselect
                                        v-model="exportParams.group"
                                        :searchable="true"
                                        :options="groups"
                                        valueProp="id"
                                        label="name"
                                        placeholder="Select group"
                                    ></multiselect>
                                </div>
                            </div>
                        </div>
                        <div class="section_wpr mt-3">
                            <h3>Export Method</h3>
                            <label for="export-csv" class="switch_option capsule_btn border-0 m-0">
                                <h5>Export as CSV <span>Includes Name, Joined, E-mail, Phone etc..</span></h5>
                                <input type="radio" name="export_method" id="export-csv" value="csv" v-model="exportParams.export_method" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="export-spreadsheet" class="switch_option capsule_btn border-0 mb-2">
                                <h5>Export as Spreadsheet <span>Includes Name, Joined, E-mail, Phone etc..</span></h5>
                                <input type="radio" name="export_method" id="export-spreadsheet" value="xls" v-model="exportParams.export_method" hidden>
                                <div><span></span></div>
                            </label>
                            <!-- <div class="action_wpr">
                                <button class="btn save_btn" @click="handleExport()"><i class="fa fa-spinner fa-spin" v-if="exportLoader"></i> {{ exportLoader ? 'Exporting' : 'Export' }}</button>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button class="btn save_btn" @click="handleExport()"><i class="fa fa-spinner fa-spin" v-if="exportLoader"></i> {{ exportLoader ? 'Exporting' : 'Export' }}</button>
            </div>
        </div>
    </div>

    <!--------------- Preferences ---------------->
    <div class="modal" v-if="toggleTablePreferences" :class="{ 'active' : toggleTablePreferences }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Preferences</h1>
                </div>
                <button class="close_btn left_out" @click="toggleTablePreferences = false;"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <h3 class="sub_heading2">Hide or show desired table elements</h3>
                    <div class="switch_wpr">
                        <label for="contact_pic" class="switch_option capsule_btn border-0 m-0">
                            <h5>Profile Pic</h5>
                            <input type="checkbox" name="contact_pic" v-model="configurations.photo" :true-value="1" :false-value="0" id="contact_pic" hidden>
                            <div><span></span></div>
                        </label>
                        <label for="temperature" id="temprature-label" class="switch_option capsule_btn border-0 m-0" :class="{'disabled' : !configurations.photo}">
                            <h5>Temperature</h5>
                            <input type="checkbox" name="temperature" v-model="configurations.temperature" :true-value="1" :false-value="0" id="temperature" hidden>
                            <div><span></span></div>
                        </label>
                        <label for="contact_flag" id="flag-label" class="switch_option capsule_btn border-0 m-0" :class="{'disabled' : !configurations.photo}">
                            <h5>Flagging</h5>
                            <input type="checkbox" name="contact_flag" v-model="configurations.starred" :true-value="1" :false-value="0" id="contact_flag" hidden>
                            <div><span></span></div>
                        </label>
                        <label for="contact_fname" class="switch_option capsule_btn border-0 m-0">
                            <h5>First Name</h5>
                            <input type="checkbox" name="contact_fname" v-model="configurations.first_name" :true-value="1" :false-value="0" id="contact_fname" hidden>
                            <div><span></span></div>
                        </label>
                        <label for="contact_lname" class="switch_option capsule_btn border-0 m-0">
                            <h5>Last Name</h5>
                            <input type="checkbox" name="contact_lname" v-model="configurations.last_name" :true-value="1" :false-value="0" id="contact_lname" hidden>
                            <div><span></span></div>
                        </label>
                        <label for="contact_date" class="switch_option capsule_btn border-0 m-0">
                            <h5>Date Added</h5>
                            <input type="checkbox" name="contact_date" v-model="configurations.date_created" :true-value="1" :false-value="0" id="contact_date" hidden>
                            <div><span></span></div>
                        </label>
                        <label for="contact_email" class="switch_option capsule_btn border-0 m-0">
                            <h5>Email</h5>
                            <input type="checkbox" name="contact_email" v-model="configurations.email" :true-value="1" :false-value="0" id="contact_email" hidden>
                            <div><span></span></div>
                        </label>
                        <label for="contact_phone" class="switch_option capsule_btn border-0 m-0">
                            <h5>Phone Number</h5>
                            <input type="checkbox" name="contact_phone" v-model="configurations.number" :true-value="1" :false-value="0" id="contact_phone" hidden>
                            <div><span></span></div>
                        </label>
                        <label for="contact_segment" class="switch_option capsule_btn border-0 m-0">
                            <h5>Current Segment</h5>
                            <input type="checkbox" name="contact_segment" v-model="configurations.status" :true-value="1" :false-value="0" id="contact_segment" hidden>
                            <div><span></span></div>
                        </label>
                        <label for="contact_action" class="switch_option capsule_btn border-0 m-0">
                            <h5>Last Action</h5>
                            <input type="checkbox" name="contact_action" v-model="configurations.last_action" :true-value="1" :false-value="0" id="contact_action" hidden>
                            <div><span></span></div>
                        </label>
                        <!-- <label for="contact_tags" class="switch_option capsule_btn border-0 m-0">
                            <h5>Tags</h5>
                            <input type="checkbox" name="contact_tags" v-model="configurations.tags" :true-value="1" :false-value="0" id="contact_tags" hidden>
                            <div><span></span></div>
                        </label>
                        <label for="contact_last_activity" class="switch_option capsule_btn border-0 m-0">
                            <h5>Last Email Activity</h5>
                            <input type="checkbox" name="contact_last_activity" v-model="configurations.last_email_activity" :true-value="1" :false-value="0" id="contact_last_activity" hidden>
                            <div><span></span></div>
                        </label>
                        <label for="contact_login_count" class="switch_option capsule_btn border-0 m-0">
                            <h5>Login Count</h5>
                            <input type="checkbox" name="contact_login_count" v-model="configurations.login_count" :true-value="1" :false-value="0" id="contact_login_count" hidden>
                            <div><span></span></div>
                        </label>
                        <label for="contact_last_chat_resp" class="switch_option capsule_btn border-0 m-0">
                            <h5>Last Chat Response</h5>
                            <input type="checkbox" name="contact_last_chat_resp" v-model="configurations.last_chat" :true-value="1" :false-value="0" id="contact_last_chat_resp" hidden>
                            <div><span></span></div>
                        </label> -->
                    </div>
                    <div class="switch_wpr">
                        <div class="other_config">
                            <label for="contact_table_header" class="switch_option capsule_btn border-0 m-0">
                                <h5>Table Header</h5>
                                <input type="checkbox" name="contact_table_header" v-model="configurations.table_header" :true-value="1" :false-value="0" id="contact_table_header" hidden>
                                <div><span></span></div>
                            </label>
                            <div class="page_item">Show<input type="number" min="0" max="100" v-model="contactsPerPage"> Contacts</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button class="btn save_btn" @click="handleApplyPreferences()">Save Preferences</button>
            </div>
        </div>
    </div>

    <!-- Unopened Search Popup -->
    <div class="modal secondary prompt" v-if="unopenedParams.type && unopenedEmailModal">
        <div class="modal_container">
            <template v-if="unopenedEmailStep == 1">
                <h2>Unopened Search</h2>
                <h5>Searching all contacts who have not opened a <strong class="capitalize">{{ unopenedParams.type }}</strong> email from you in <strong>{{ unopenedParams.days }}</strong> days. The tag below will be applied to contacts maching this search criteria for easy bulk actions later.</h5>
                <div class="tag_wpr">
                    <span>{{ unopenedParams.tag }}</span>
                    tag will be applied to these contacts
                </div>
                <div class="action_wpr mt-3">
                    <button type="button" :disabled="unopenedEmailsFilterLoaderStatus" class="btn save_btn"  @click="handleUnopenedEmailFilter(2)">
                        <template v-if="unopenedEmailsFilterLoaderStatus">
                            <i class="fa fa-spinner fa-spin"></i> Processing
                        </template>
                        <template v-else>
                            Next <i class="fas fa-long-arrow-alt-right"></i>
                        </template>
                    </button>
                </div>
            </template>
            <template v-if="unopenedEmailStep == 2 ">
                <h2>Search Complete </h2>
                <h5>Please allow a few minutes for the tagging to finish. Once complete, you can search the tag below and perform bulk actions on these contacts.</h5>
                <div class="tag_wpr">
                    <span>{{ unopenedEmailsContacts.contacts }}</span> contacts have  <span>{{ unopenedParams.tag }}</span> tag added
                </div>
                <div class="action_wpr mt-3">
                    <button type="button" class="btn save_btn" @click="handleUnopenedEmailFilter(3)"> Finish</button>
                </div>
            </template>
            <template v-if="unopenedEmailStep == 3">
                <h2>Search Complete </h2>
                <h5>Note: ThriveCoach may still be in the process of tagging all contacts. If the number of tagged contacts does not match the reported total, kindly wait a few minutes.</h5>
                <div class="action_wpr mt-3">
                    <button type="button" class="btn cancel_btn"  @click="unopenedEmailModal = false;">Close</button>
                    <button :to="{ name: 'TagIndex' }" type="button" @click="handleUnopenedEmailFilter(4)" class="btn save_btn"> Open Tag center </button>
                </div>
            </template>
            <template v-if="unopenedEmailStep == 5">
                <h2>Search Complete </h2>
                <h5>No contacts found.</h5>
                <div class="action_wpr mt-3">
                    <button type="button" class="btn cancel_btn"  @click="unopenedEmailModal = false;">Close</button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions, mapMutations } from 'vuex'

    const StatusTabsModal = defineAsyncComponent(() => import('@/pages/contact/components/StatusTabsModal'))
    const ContactAddWizard = defineAsyncComponent(() => import('@/pages/contact/components/AddWizard'))
    const SearchFilter = defineAsyncComponent(() => import('@/pages/contact/components/SearchFilter'))

    import moment from 'moment'

    export default {
        name: 'Contact Header',

        data () {
            return {
                tutorialList: false,
                tutorial: false,
                exportInfo: false,
                exportParams: {
                    from: '',
                    to: '',
                    all: 1,
                    fields: [],
                    export_method: 'csv',
                    status: '',
                    tags: [],
                    group: '',
                    suppression: 1,
                },
                toggleTablePreferences: false,
                configurations: {
                    photo: 1,
                    temperature: 1,
                    starred: 1,
                    first_name: 1,
                    last_name: 1,
                    date_created: 1,
                    email: 1,
                    number: 1,
                    status: 1,
                    last_action: 1,
                    tags: 0,
                    login_count: 0,
                    last_email_activity: 0,
                    last_chat: 0,
                    table_header: 1,
                },
                contactAddWizard: false,
                exportLoader: false,
                allDates: true,
                maxStartDate: moment().format('YYYY-MM-DD'),
                minEndDate: '',
                editStatusTabs: false,
                filterComponent: {},
                contactsPerPage: localStorage.getItem('contacts_per_page') ? parseInt(localStorage.getItem('contacts_per_page')) : 5,
                contactSearch: '',
                unopenedEmailModal: false,
                unopenedEmailStep: 1,
                unopenedParams: {
                    days: 7,
                    type: '',
                    tag: '',
                }
            };
        },

        props: {
            clearFilter: Function,
            filterFunc: Function,
            hasFilter: Object,
            modelValue: Boolean,
            filterAply: Function,
            refreshContacts: Function,
            handleContactSearch: Function,
        },

        emits: ['update:modelValue'],

        components: {
            StatusTabsModal,
            ContactAddWizard,
            SearchFilter,
        },

        watch: {
            exportInfo (val) {
                const vm = this;

                vm.resetExport();

                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            contactAddWizard (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            'exportParams.to' (to) {
                const vm = this;

                if (to) {
                    vm.maxStartDate = moment(to).format('YYYY-MM-DD');
                } else {
                    vm.maxStartDate = moment().format('YYYY-MM-DD');
                }
            },

            'exportParams.from' (from) {
                const vm = this;

                if (from) {
                    vm.minEndDate = moment(from).format('YYYY-MM-DD');
                }
            },

            modelValue (val) {
                const vm = this;

                if (val == true) {
                    vm.resetUnopenedFilter();
                    document.body.classList.add('modal-open');
                    vm.configurations   = JSON.parse(JSON.stringify(vm.tablePreferences));
                } else {
                    document.body.classList.remove('modal-open');
                }

            },

            toggleTablePreferences (val) {
                const vm = this;

                if (val == true) {
                    document.body.classList.add('modal-open');
                    vm.configurations   = JSON.parse(JSON.stringify(vm.tablePreferences));
                } else {
                    document.body.classList.remove('modal-open');
                }

            },

            contactSearch (search) {
                const vm = this;

                vm.handleContactSearch(search, true);
            },

            tablePreferences: {
                handler (tablePreferences) {
                    const vm = this;

                    vm.configurations   = JSON.parse(JSON.stringify(tablePreferences));
                },
                deep: true,
            },

            'unopenedParams.type' (type) {
                const vm = this;

                if (type) {
                    vm.unopenedParams.days = 7;
                    vm.unopenedEmailStep = 1;
                }
            },

            unopenedEmailModal (val) {
                const vm = this;

                if (val && vm.unopenedEmailStep == 1) {
                    vm.filterContactsByUnopenedEmails(vm.unopenedParams);
                }
            }
        },

        computed: mapState({
            user: state => state.authModule.user,
            showContactRefreshButton: state => state.contactModule.showContactRefreshButton,
            tagsGroup: state => state.tagModule.tagsGroup,
            statusesGroup: state => state.contactModule.statusesGroup,
            statusTabsLoader: state => state.contactModule.statusTabsLoader,
            tablePreferences: state => state.contactModule.tablePreferences,
            unopenedEmailsFilterLoaderStatus: state => state.contactModule.unopenedEmailsFilterLoaderStatus,
            unopenedEmailsContacts: state => state.contactModule.unopenedEmailsContacts,
            groups: state => state.groupModule.groups,
        }),

        mounted () {
            const vm = this;

            setTimeout(function () {
                const filterComponent = vm.$refs['search-filter'];

                if (filterComponent) {
                    vm.filterComponent = filterComponent;
                }
            }, 1000);

            if (localStorage.getItem('contacts_params')) {
                vm.contactSearch = JSON.parse(localStorage.getItem('contacts_params')).search;
            }

            if (!vm.tagsGroup.length) {
                vm.getTags();
            }

            if (!vm.groups.length) {
                vm.getGroups();
            }

            vm.refreshContactPreferences();
            vm.configurations   = JSON.parse(JSON.stringify(vm.tablePreferences));
            vm.contactsPerPage  = localStorage.getItem('contacts_per_page') ? parseInt(localStorage.getItem('contacts_per_page')) : 5;
        },

        methods: {
            ...mapActions({
                exportContact: 'contactModule/exportContact',
                getGroups: 'groupModule/getGroups',
                getTags: 'tagModule/getTags',
                updateContactPreferences: 'authModule/updateContactPreferences',
                refreshContactPreferences: 'authModule/refreshContactPreferences',
                filterContactsByUnopenedEmails: 'contactModule/filterContactsByUnopenedEmails',
                applyUnopenedTag: 'contactModule/applyUnopenedTag',

            }),

            ...mapMutations({
                setTablePreferences: 'contactModule/SET_CONTACT_TABLE_PREFRENCES',
            }),

            resetExport () {
                const vm = this;

                vm.exportParams = {
                                      from: '',
                                      to: '',
                                      all: 1,
                                      fields: [],
                                      export_method: 'csv',
                                      status: '',
                                      tags: [],
                                      group: '',
                                  };
            },

            handleExport () {
                const vm = this;

                vm.exportLoader = true;

                vm.exportParams.from = moment(vm.exportParams.from).format('YYYY-MM-DD');
                vm.exportParams.to = moment(vm.exportParams.to).format('YYYY-MM-DD');

                vm.exportContact(vm.exportParams).then((result) => {
                    if (result) {
                        vm.exportInfo = false;
                    }

                    vm.exportLoader = false;
                });
            },

            closeConfiguration () {
                const vm = this;
                vm.toggleTablePreferences = false;
            },

            closeFilterInfo () {
                const vm = this;
                vm.$emit('update:modelValue', false);
            },

            closeTutorialListDropdown () {
                const vm = this;

                vm.tutorialList = false;
            },

            toggleAllDates() {
                const vm = this;

                if (vm.allDates) {
                    vm.exportParams.from = '';
                    vm.exportParams.to = '';
                }
            },

            handleFilterApply () {
                const vm = this;
                const filterComponent = vm.$refs['search-filter'];

                if (vm.unopenedParams.type && vm.unopenedParams.days) {
                    if (vm.unopenedParams.type == 'broadcast') {
                        vm.unopenedParams.tag = `${vm.unopenedParams.days}BU-${moment().format('MMM-DD-YY')}`;
                    } else if (vm.unopenedParams.type == 'sequence') {
                         vm.unopenedParams.tag = `${vm.unopenedParams.days}SU-${moment().format('MMM-DD-YY')}`;
                    } else {
                        vm.unopenedParams.tag = `${vm.unopenedParams.days}AU-${moment().format('MMM-DD-YY')}`;
                    }

                    vm.$emit('update:modelValue', false);
                    vm.unopenedEmailModal = true;
                } else {
                    if (filterComponent) {
                        vm.filterComponent = filterComponent;

                        vm.filterAply();
                    }
                }
            },

            handleApplyPreferences () {
                const vm = this;

                localStorage.setItem('contacts_per_page', vm.contactsPerPage);

                vm.updateContactPreferences({ id: vm.user.id, contact_preferences: vm.configurations });
                vm.setTablePreferences(vm.configurations);
                vm.refreshContacts(vm.contactsPerPage);
                vm.closeConfiguration();
            },

            forceContactSearch () {
                const vm = this;

                vm.handleContactSearch(vm.contactSearch, true, true);
            },

            handleUnopenedEmailFilter (step) {
                const vm = this;

                if (step == 3) {
                    if (vm.unopenedEmailsContacts.contacts) {
                        vm.unopenedEmailStep = step;
                    } else {
                        vm.unopenedEmailStep = 5;
                    }
                } else if (step == 4) {
                    vm.$router.push({ name: 'TagIndex', query: { tag_id: vm.unopenedEmailsContacts.tag } });
                } else {
                    vm.unopenedEmailStep = step;
                }

                vm.getTags();
            },

            resetUnopenedFilter () {
                const vm = this;

                vm.unopenedParams.days = 7;
                vm.unopenedParams.type = '';
                vm.unopenedEmailModal  = false;
                vm.unopenedEmailStep   = 1;
            }
        },
    }
</script>

<style scoped>
    .section_header{
        justify-content: flex-start;
        column-gap: 0;
    }
    .section_header .add_btn{
        height: 35px;
        background: #2f7eed;
        border-radius: 4px;
        padding: 0 15px;
        color: #fff;
        font-size: 13px;
        line-height: 16px;
        margin: 0;
    }
    .section_header .add_btn img{
        max-height: 16px;
        width: auto;
        padding-right: 8px;
        filter: brightness(100);
    }
    .section_header .add_btn i{
        color: #fff !important;
        font-size: 15px !important;
        margin-right:10px !important;
    }
    .section_header > ul li:not(.search_area){
        height: 35px;
        width: 35px;
        background: #2f7eed;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
    }
    .section_header ul li.export > h5{
        border: 0;
        padding: 0;
        line-height: 0;
    }
    .section_header ul li img {
        max-height: 18px;
        width: auto;
        filter: brightness(100);
    }
    .section_header .search_area {
        padding: 0 31px 0 0;
        border-radius: 17px;
        background: #fff;
        position: relative;
        margin-right: 10px;
    }
    .section_header .search_area input[type=text] {
        font-size: 11px;
        height: 35px;
        width: 230px;
        background: transparent;
        padding: 0 0 0 15px;
        transition: all 0.3s ease-in-out;
    }
    .section_header .search_area .search_btn {
        position: absolute;
        right: 0;
        top: 0;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        font-size: 11px;
        color: #121525;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .section_header .search_area .search_btn i{
        font-size: 11px;
    }
    .page_item{
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        padding: 5px 0;
    }
    .page_item input[type=number]{
        width: 60px;
        height: 24px;
        min-height: 28px;
        padding-left: 10px;
        display: inline-block;
        margin: 0 5px;
        border: 1px solid #e9e9e9;
        border-radius: 3px;
        font-size: 13px;
    }
    .section_header .tutorial_btn{
        position: relative;
        display: flex;
        align-items: center;
        margin-left: 20px;
        cursor: pointer;
    }
    .section_header .tutorial_btn label {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #2f7eed;
        cursor: pointer;
    }
    .section_header .tutorial_btn img {
        max-height: 18px;
        width: auto;
        margin-left: 5px;
    }
    .section_header .tutorial_btn .dropdown_wpr{
        width: max-content;
        min-width: 160px;
        z-index: 12;
        overflow: hidden;
    }
    .section_header .tutorial_btn .dropdown_wpr ul li{
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        border: 0;
    }
    .switch_wpr{
        padding: 15px 20px;
        background: #fff;
        border-radius: 5px;
        margin-bottom: 20px;
    }
    .switch_option.disabled{
        opacity: 0.8;
        pointer-events: none;
    }

    #temprature-label.capsule_btn.disabled input[type="checkbox"]:checked ~ div span,
    #flag-label.capsule_btn.disabled input[type="checkbox"]:checked ~ div span {
        left: 2px;
        right: auto;
        background: #F2A31D;
    }

    .unopens-filter h5 {
        font-size: 13px;
        line-height: 16px;
        margin-right: 10px;
        font-weight: 500;
        color: #5a5a5a;
    }

    .unopens-filter h5 span {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #6a6a6a;
        display: block;
    }

    .unopens-filter input {
        max-width: 50px;
        border: 1px solid #e9e9e9;
        padding: 2px;
        font-size: 13px;
    }

    .modal.prompt h5 {
        max-width: 425px;
        /* display: flex; */
        justify-content: center;
        align-items: center;
    }

    .modal.prompt .logo_img {
        max-height: 90px;
        width: auto;
        margin-bottom: 30px;
    }

    .modal_container .tag_wpr span {
        padding: 5px 10px;
        border: #6a6a6a 1px solid;
        border-radius: 5px;
    }

    .modal_container .tag_wpr {
        margin: 10px 10px 10px 0 ;
    }

    @media(max-width: 767px){
        .section_header ul{
            width: 100%;
        }
        .section_header ul li.export .dropdown_wpr {
            width: 330px;
            left: 1px;
            right: auto;
        }
        .section_header ul li.export .dropdown_wpr:before{
            left: 25px;
        }
        .section_header ul li.export .dropdown_wpr.filter_box{
            left: -50px;
        }
        .section_header ul li.export .dropdown_wpr.filter_box:before{
            left: 80px;
        }
        .section_header .tutorial_btn{
            margin-left: auto;
        }
        .section_header .tutorial_btn .dropdown_wpr{
            left: auto;
            right: 1px;
        }
        .section_header .search_area{
            margin-right: auto;
        }
    }
    @media(max-width: 499px){
        .section_header .search_area input[type=text] {
            height: 30px;
            width: 160px;
        }
        .section_header > ul li:not(.search_area){
            height: 30px;
            width: 30px;
        }
        .section_header ul li img{
            max-height: 16px;
        }
    }
</style>
